.modalWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    top: 0;
    right: 0;
}

.payContainer {
    position: relative;
    flex-direction: column;
    justify-content: center;
    width: 36em;
    max-width: 95%;
    padding: 1.25em;
    border-radius: 0.3125em;
    background: #fff;
    font-family: inherit;
    font-size: 1rem;
    box-sizing: border-box;
    text-align: center;
    z-index: 110;

    max-height: 80%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.payHeader {
    display: block;
    position: relative;
    max-width: 100%;
    margin: 0 16px;
    padding: 0;
    color: #0202029D;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    word-wrap: break-word;
}

.payInfo {
    letter-spacing: 0;
    color: #0202029D;
    font-weight: 700;
    text-align: center;
    margin: 6px 0px;
}

.payHeader img {
    width: 180px;
}

.subTitle {
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    color: #0202029D;
    text-align: center;
    padding-bottom: 12px;
}

.payBox form {
    margin-left: 3%;
    margin-right: 3%;
    width: 94%;
}

.payBox form table {
    width: 100%;
    text-align: left;
    border-spacing: 2px;
    font-size: 18px;
}

.payBox input,
.payBox select {
    width: 100%;
    padding: 12px 16px;
    margin: 8px 0;
    font-size: 17px;
    display: inline-block;
    border: 1px solid #eee;
    border-radius: 4px;
    box-sizing: border-box;
    color: #0202029D !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    margin-top: -2px;
}

.payBox ::placeholder {
    color: rgb(151, 151, 151) !important;
}

.payBox input[type="image"] {
    margin: 0;
    margin-bottom: -28px;
    background-color: #fff;
    cursor: pointer;
}

.payBox input[type="hidden"] {
    margin: 8px 0 2px 0;
}


.displayNone {
    display: none !important;
}

.closeModal {
    position: absolute;
    right: 16px;
    top: 10px;
    width: 24px;
    font-weight: 700;
    font-size: 20px;
    cursor: pointer;
    z-index: 111;
}

.payPalPaidBtn {
    margin-top: 16px;
    width: 100%;
    max-width: 280px;
}

.payPalButton {
    background: #FFC43B 0% 0% no-repeat padding-box;
    box-shadow: -8px 5px 20px #FFC43BC7;
    border-radius: 85px;
    border: none;
    width: 70%;
    text-align: center;
    color: #003087;
    font-size: 22px;
    font-weight: 700;
    padding: 12px 0px;
    margin: 8px auto 0px auto;
    cursor: pointer;
}

.payPalButton .sec {
    color: #029bdd;
}

.payPalButton img {
    height: 27px;
    margin: 2px 4px 0px 4px;
}

.payPalButton:disabled,
.payPalPaidBtn:disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: not-allowed;
}

.invalid {
    margin-left: 4px;
    font-size: 14px;
    color: #f44336;
    margin-top: -2px;
}

.triInput {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
}

.triInput>div {
    width: 31%;
    /* display: inline-block; */
}


.checkoutFormContainer {
    border: 1px solid #eee;
    border-radius: 4px;
    box-sizing: border-box;
    color: #0202029D !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    padding: 0px 8px;
    margin-bottom: 8px;
}

.checkoutFormLabel {
    text-align: left;
    margin: 16px 0 2px 0px;
    font-size: 17px;
}

button.submitBtn {
    background-color: #FF7D3E;
    color: #fff !important;
    padding: 6px 16px;
    border-radius: 20px;
    font-size: 16px;
    text-transform: none;
    font-weight: 400;
    margin: 4px;
}

button.submitBtn:hover {
    background-color: #ff4e1c;
}

button.submitBtn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.colorPrimary {
    color: #FF7D3E !important;
}

@media (max-height: 900px) {
    .payHeader {
        margin: 0 12px;
    }

    .payHeader img {
        width: 140px;
        margin-top: -8px;
        margin-bottom: -4px;
    }

    .subTitle {
        font-size: 26px;
        padding-bottom: 8px;
    }

    .payInfo {
        display: none;
    }

    .payHeader {
        font-size: 1.3rem;
        margin: 0 0 4px 0;
    }

    .payBox input[type="image"] {
        margin: 0;
        margin-bottom: -36px;
    }

    .payPalButton {
        font-size: 18px;
        padding: 8px 0px;
    }

    .payPalButton img {
        height: 25px;
        margin: 2px 4px 0px 4px;
    }


    .payBox form table {
        font-size: 16px;
    }

    .payBox input,
    .payBox select {
        padding: 8px 12px;
        margin-bottom: 2px;
    }
}
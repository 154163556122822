.tableCustomInput {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    float: right;
}

div.tableCustomInput:after {
    border-color: inherit;
}

/* tbody.stripped>tr:nth-of-type(odd) {
    background-color: #f9f9f9
} */

@media (max-width: 460px) {
    .toolbar {
        padding-left: 2px;
        padding-right: 2px;
    }

    .selectRoot {
        margin-left: 0px;
        margin-right: 8px;
    }

    .actions {
        margin-left: 4px;
    }

    .actions button {
        padding: 12px 6px;
    }
}


@media (max-width: 360px) {
    .caption {
        font-size: 12px;
        width: 50px;
    }
}

.tableScroll {
    max-height: 500px;
}

.tableScroll::-webkit-scrollbar {
    width: 6px;
    background-color: #89B0CF;
    transition: all 500ms ease;
}

/* .tableScroll:hover::-webkit-scrollbar {
    width: 9px;
} */


.tableScroll::-webkit-scrollbar-track {
    margin-top: 46px;
    background: #f0f0f0;
    width: 5px;
}

.tableScroll::-webkit-scrollbar-thumb {
    background: #ccc;
    width: 5px;
}
.modalWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 200;
    top: 0;
    right: 0;
}

.container {
    width: 700px;
    max-width: 90%;
    height: max-content;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 40px #00000029;
    opacity: 1;
    padding: 50px 20px;
    letter-spacing: 0;
    color: #0202029D;
    text-align: center;
    position: relative;
    z-index: 210;
}

.header {
    width: 60%;
    margin: 30px auto 20px auto;
}

.logoImg {
    max-width: 100%;
}

.title {
    font-size: 40px;
    font-weight: 700;
    line-height: 44px;
}

.info {
    font-size: 32px;
    margin: 16px;
    letter-spacing: 0;
    line-height: 40px;
    color: #0202029D;
}

.closeModal {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 24px;
    font-size: 34px;
    cursor: pointer;
    z-index: 211;
}


@media (max-width: 780px) {
    .container {
        max-width: 80%;
    }

    .header {
        width: 100%;
        margin: 20px auto 20px auto;
    }

    .title {
        font-size: 32px;
        line-height: 38px;
    }

    .info {
        font-size: 24px;
        margin: 12px;
        line-height: 30px;
    }

}
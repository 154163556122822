.footerPadding {
    padding: 15px;
}

@media (max-width: 420px) {
    .footerPadding {
        padding: 15px 4px;
    }
}

/*@media only screen and (min-width: 390px) and (max-width: 767px) {
    .container {
        position: fixed !important;
    }

    .right {
        margin-left: 18px;
        float: right !important;
    }
}*/

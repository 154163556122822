.credit_symbol{
    display: 'flex';
    justify-content: center;
    align-items: center;
    font-weight: 500;
    background-color: white;
    color: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    padding: 0px 5px;
    font-size: '11px !important';
    cursor: pointer;
    height: '100%';
}

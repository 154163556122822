.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid black;
    position: absolute;
    right: 12px;
    top: 45%;
    transition: all 150ms ease-in;
}

.nestedItemContainer {
    margin-left: 35px;

}
.verticalDivider {
    position: absolute;
    width: 0.5px;
    margin-left: 10px;
    margin-top: 6px;
    height: 100%;
    background: #F74C54;
}

.active .caret {
    transform: rotate(180deg);
}


.active .boldText {
    font-weight: 700 !important;
    color: #000 !important;
}

.nestedNavItem {
    margin-right: 4px !important;
    padding-left: 10px !important;
}

.nestedNavActive {
    text-decoration: none !important;
    background-color: rgba(0, 0, 0, 0.08) !important;
}

.fullReportBtn {
    margin-bottom: 24px;
}

.fullReportBtn>div {
    border-radius: 10px !important;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .36), 0 7px 10px -5px rgba(255, 125, 62, .5);
}

.freeReportBtn {
    margin-bottom: 12px;
}

.freeReportBtn>div,
.freeReportBtn>div:hover,
.freeReportBtn>div:focus {
    border-radius: 10px !important;
    background: #00B4CB 0% 0% no-repeat padding-box;
    box-shadow: -8px 5px 20px #68CFF7C7;
    border: 1px solid #68CFF7;
}

.iconRightPadding {
    margin-right: 8px !important;
    margin-left: -2px;
}

.rlList li {
    cursor: pointer;;
}

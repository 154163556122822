.googleAuthBtn {
    color: #fff !important;
    float: right;
    margin-right: 16px;
}

.googleAuthBtn>div {
    margin-right: 0px !important;
    padding: 8px 10px 6px 10px !important;
}

.googleAuthBtn>span {
    display: flex;
    color: white;
    padding: 10px 10px 10px 5px !important;
    background-color: #4285f4;
    font-weight: 400 !important;
    font-size: 16px;
}

.signedInBox {
    margin-right: 28px;
    margin-left: 0px;
}

.signedDiv {
    display: none;
    margin-top: 4px;
}

.signedInBox:hover .signedDiv,
.signedInBox:focus .signedDiv {
    display: block;
}

.avatar {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.drawerToggle {
    fill: #fff !important;
}

/* .googleLogoutBtn>div {
    display: none;
}

.googleLogoutBtn>span {
    padding: 5px 10px;
}
 */

@media screen and (max-width: 960px) {

    .googleAuthBtn,
    .signedInBox {
        float: left;
        margin-right: 0px;
        margin-left: 16px;
    }

    .signedInBox {
        margin-left: 28px;
        position: absolute;
        right: auto;
        left: 0;
        top: 0;
        align-items: flex-start;
    }

}





/* REPORT LOADING CSS */
.searchDetails {
    width: fit-content;
}

.keywords, .location {
    font-family: 'Roboto', sans-serif;
    font-weight: 500 !important;
    font-size: 17px;
    /* letter-spacing: 1.4px !important; */
    /* line-height: 0.1; */
    padding: 0;
    margin: 5px;
}
.location {
    /* width: 220px; */
    max-width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.locationsContainer {
    display: flex;
    align-items: center;
    margin-top: -10px;
}
.suggestionsContainer {
    text-align: center;
    padding-top: 3px;
    margin-left: 10px;
}
.doSuggestions {
    width: fit-content;
    font-size: 13px !important;
    /* margin-left: 20px !important; */
} 

.navList {
    display: flex;
    margin-right: 0px;
    font-size: 15px;
    list-style: none;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    font-family: Roboto, Helvetica, Arial, sans-serif, 'Franklin Gothic Medium';;
}

.navList li {
    padding: 0px 10px;
    cursor: pointer;
}

.navList li a {
    color: inherit;
}

/*Request a demo*/

.demoBtn {
    /* background-color: #1AAAC1 !important; */
    /* color: #fff !important; */

    color: #F75054 !important;
    margin: 5px 10px 5px 0px !important;
    font-size: 11px !important;
    font-weight: 500 !important;
    min-width: 120px !important;
    border: solid !important;
    border-color: #F75054 !important;
    background-color: whitesmoke !important;
    text-transform: none !important;
}

.demoBtn:hover,
.demoBtn:focus {
      box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
      transform: translateY(-0.25em);
      background-color: whitesmoke !important;
    }


/*Making it responsive*/
@media screen and (max-width: 1200px) {
    .logoResponsive {
        height: 40px !important;
    }
    .logoConResponsive{
        margin-right: 25px !important;
    }
    .keywords, .location, .navList{
        font-size: 0.9rem;
    }
    .doSuggestions {
        font-size: 12px !important;
    }
}
@media screen and (min-width: 960px) and (max-width: 1000px) {
    .logoResponsive {
        height: 32px !important;
    }
    .logoConResponsive{
        margin-right: 25px !important;
    }
    .navList{
        margin-right: 0 !important;
    }
    .navList li {
        padding: 0px 7px !important;
    }
    .keywords, .location, .navList{
        font-size: 0.8rem;
    }
}
@media screen and (max-width: 960px) {
    .logoResponsive {
        height: 30px !important;
    }
    .logoConResponsive{
        margin-right: 25px !important;
    }
    .navList{
        display: none;
        margin-right: 0 !important;
    }
}
@media screen and (max-width: 576px) {
   .searchDetails {
       display: none;
   }
   .authIcon {
       justify-content: flex-end !important;
   }
}


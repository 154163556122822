.drawer {
    width: 45%;
    background: #fff;
    justify-content: flex-start;
}

@media screen and (max-width: 1200px) {
    .drawer {
        width: 50%;
    }
}

@media screen and (max-width: 992px) {
    .drawer {
        width: 60%;
    }
}

@media screen and (max-width: 768px) {
    .drawer {
        width: 75%;
    }
}

@media screen and (max-width: 576px) {
    .drawer {
        width: 90%;
    }
}

.headerContainer {
    background: rgb(97, 120, 139);
    padding: 16px 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.actionFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #809FB8;
    padding: 8px;
}

.drawerBody {
    height: 100px;
    top: 0;
}

.headerTitle {
    margin: 0;
    color: #fff;
    font-weight: 700;
    letter-spacing: 1px !important;
    font-size: 24px;
    margin-left: 8px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.creditCounter {
    text-transform: none;
    font-size: 1.05rem !important;
    font-weight: 500 !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.bodyContainer {
    padding: 17px 4px 17px 4px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.actionInput {
    padding: 0px 15px;
}

.inputAdorn {
    font-weight: 600 !important;
    font-size: .9rem !important;
}
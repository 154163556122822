.signedInBox {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-end;
    width: max-content;
    float: right;
    margin-right: 20px;
    margin-left: 0px;
    right: 0;
    top: 0;
}

.logOutButton {
    position: absolute;
    left: 0;
    background: #E2E2E2;
    color: #777777;
    width: 100px;
    margin-left: -84px;
    cursor: pointer;    
    padding: 10px 10px 10px 0px;
}
.logOutButton span {
    font-weight: 500;
    font-size: 14px;
}
.signedDiv {
    display: none;
    flex-flow: column nowrap;
    align-items: flex-start;
    margin-top: 6px;
    background: rgb(226, 226, 226);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    position: relative;
}

.signedDiv .caretSvg {
    position: absolute;
    width: 30px;
    transform: rotate(180deg);
    top: -18px;
    right: 0;
}

.signedDiv .caretSvg path {
    fill: #e2e2e2;
}

.signedInBox:hover .signedDiv,
.signedInBox:focus .signedDiv {
    display: flex;
}

.avatar {
    vertical-align: middle;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    border: 1px solid gray;
}

.signedInButton {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    /* background-color: #FF5A09; */
    /* border-radius: 0px 0px 0px 30px; */
    border-radius: 100%;
    /* border-top-left-radius: 30px; */
    /* border-bottom-left-radius: 30px; */
    color: #fff;
    background-color: #809fb8;
    font-size: 23px;
    font-weight: 400;

}

.signedInButton>svg {
    width: inherit;
    height: inherit;
}

.userName {
    font-size: 15px;
    font-weight: 500;
    padding: 0px 6px;
}


.navItem {
    display: flex;
    font-size: 14px;
    padding: 10px 12px;
    border-bottom: 1px solid lightgray;
    color: #555555;
    font-weight: 500;
    opacity: 0.7;
}

.navItem:hover,
.navItem:focus {
    opacity: 1;
    color: #555555;
    background: #ededed;
}


.deliveryCls {
    display: inline-block;
    height: 30px;
    width: auto;
    margin: -5px -5px -5px -12px;
}

.navItem:hover .deliveryCls g {
    fill: #FF5A09 !important;
}

.googleLogoutBtn {
    position: absolute;
    z-index: 100;
    left: -100px;
    width: 120px;
    background: rgb(226, 226, 226) !important;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    box-shadow: none !important;
}

.googleLogoutBtn>div {
    display: none;
}

.googleLogoutBtn>span {
    padding: 5px 10px;
    color: #555555;
    font-size: 14px;
    font-weight: 500;
}

.logOutIco {
   
    width: 40px;
    height: 36px;
    margin: -10px -5px -13px 12px;
}

.logOutIco g {
    fill: #777777 !important;
}

.googleLogoutBtn:hover .logOutIco g {
    fill: #F74C54 !important;
}


.googleLogoutBtn:hover,
.googleLogoutBtn:focus {
    opacity: 1;
    color: #555555;
    background: #ededed !important;
}

@media screen and (max-width: 1200px) {
    .signedInButton {
        width: 40px;
        height: 40px;
    }
}

@media screen and (max-width: 960px) {

    .signedInButton {
        /* padding: 5px 6px 5px 5px; */
        border-radius: 0px 30px 0px 0px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
    }

    .signedInBox {
        float: right;
        margin-right: 0px;
        margin-left: 16px;
    }

    .signedInBox {
        margin-left: 28px;
    }
}
@media screen and (max-width: 576px) {
   .signedInBox {
        margin-left: 10px;
    }
    .signedInButton {
        width: 28px;
        height: 28px;
    }
}
